.header {
  padding: 10px 20px;
  display: flex;
  align-items: end;
  border-bottom: 0;
  height: 85px;

  div figure {
    width: 85px;
  }

  .simple_btn_outline {
    margin: 0;
  }

  & > div {
    width: 100%;
    align-items: center;
  }

}
.main .solution_title {
  font-size: 25px;
}
.main .services > div:last-child, .main .integration > div:last-child, .main .crm > div:last-child {
  width: 100%;
}
.main .new-service .service_host_block img {
  width: 100%;
  height: 100%;
}
.main {
  .first_hr {
    margin-top: 0;
  }

  .contact_us{
    .download,.brand_block{
      padding: 0 20px;
    }
  }

  .about {
    h1 {
      font-size: 24px;
      font-weight: 600;
    }

    p {
      padding: 0;
    }

    .container {
      padding: 0;
    }

    h3 {
      font-size: 21px;
      line-height: 24px;
    }
  }

  margin-top: 105px;

  .main_container {
    padding: 0 20px;
  }

  .main_container.crm {
    padding: 0;
  }

  .top_banner {
    padding-right: 0;
    min-height: 395px;

    div:first-child {
      width: 60%;
      padding: 25px 0;

      .h3 {
        font-family: Lato, sans-serif;
        font-size: 23px;
        font-weight: 700;
        text-align: left;

      }
    }

    &_p {
      font-size: 15px;
    }

    div:last-child {
      width: 40%;
    }

    &_img {
      display: block;
      object-position: -250px;
      width: 100%;
      border-radius: 0;
      border: 1px solid #ccc;
      height: 100%;
      object-fit: cover;
      mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 23%);
    }
  }
  .calc{
    padding: 0;
    .calculator{
      padding: 18px;
      .p-num-prices--panel{
        display: flex;
        justify-content: space-between;
        h3 {
          font-size: 12px;
          margin-bottom: 30px;
          width: 175px;
        }
        h4{
          font-size: 18px;
        }
        .signup--btn {
          padding: 10px 7px;
          border-radius: 8px;
          margin-top: 10px;
        }
      }
    }
  }

  .client {
    margin: 0;

    &_top_figure {
      flex-wrap: wrap;
      justify-content: unset;

      figure {
        margin: 2px;
        height: 35px;
      }
    }

    &_title {
      justify-content: center
    }

    a {
      display: flex;
      justify-content: center;
    }

    &_figure {
      padding: 3px 5px;
    }
  }

  .solution_install {
    background: #0973BA;
    margin-top: 15px;
    border-radius: 20px 20px 0 0;
    min-height: 380px;
  }

  .install {
    display: flex;
    color: white;
    padding: 20px 20px 0;

    & > div {
      width: 80%;
      overflow: hidden;

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
        overflow: hidden;

      }
    }

    & > figure {
      width: 20%;

      img {
        width: 100%;
      }

      figcaption {
        margin: 10px 0;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        overflow: hidden;

        a {
          color: white;

        }
      }
    }
  }

  .solution {
    background: white;
    border-radius: 20px 20px 0 0;
    flex-direction: column;
    padding-top: 15px;

    div:last-child,
    div:first-child {
      width: 100%;
      padding: 0 20px;

      p {
        margin: 0;
      }
    }

    &_video {
      border-radius: 5px;
    }
  }

  .carousel_banner {
    margin: 20px 0;

    &_dev {
      overflow: hidden;
    }

    &_p {
      width: 100%;
      padding: 0;
      line-height: 18px;

      &.text_center{
        p{
          text-align: center;
        }
      }
      p {
        overflow: hidden;

      }
    }

    &_service {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;
    }

    &_1 {
      .slick-dots {
        width: 90%;

      }

      .slick-slider.slick-initialized,
      .slick-list,
      .slick-track {
        min-height: 350px;
      }
    }

    &_2 {
      .carousel_banner_dot {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .slick-track {
        height: 550px;
      }

      .slick-slider.slick-initialized {
        height: 550px;
      }

      figure {
        width: 180px;
        height: 180px;
        margin-bottom: 10px;
        padding: 0;
      }

      .slick-dots {
        width: 100%
      }

      .slick-list {
        height: 473px;
      }
    }


  }

  .carousel_banner_img_block {
    flex-direction: column;
  }

  .service_host_block {
    background: white;
    padding: 0;
    color: black;
    height: 340px;

    & > div:last-child,
    & > .operator {
      width: 100%;
      border: none;
      color: black;
      min-height: 340px;
      overflow: hidden;

      div {
        width: 100%;
        display: flex;

        figure:nth-child(odd),
        figure:nth-child(even) {
          padding: 0;
          width: 100px;
        }
      }
    }
  }

  .fax_header {
    font-family: Lato, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;

  }

  .download_block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    border-top: 1px solid;

    h3 {
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: left;
      overflow: hidden;
    }

    span {
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      overflow: hidden;
    }
  }

  .about {
    &_block {
      padding: 0;
      display: flex;
      flex-direction: column;

      .control_about {
        width: 100%;
        border-bottom: none;

        h1, h3 {
          overflow: hidden;
        }

        img {
          float: left;
        }
      }

      .connect {
        width: 100%;
        border-bottom: none;
      }
    }

    p {
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      overflow: hidden;
    }
  }

  .developer {
    flex-direction: column;

    & > div:first-child {
      width: 100%;

      p {
        font-family: Open Sans, sans-serif;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        text-align: left;
        overflow: hidden;

      }

      .dev {
        width: 100%;
        border: none;
        padding: 0;

        p {
          font-family: Open Sans, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          overflow: hidden;

        }
      }

      .api_block_p {
        font-family: Open Sans, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        overflow: hidden;

      }

      .api_header {
        align-items: center;

        h3 {
          font-family: Open Sans, sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          text-align: left;
          overflow: hidden;
          margin-bottom: 0;
        }
      }
    }

    & > div:last-child {
      width: 100%;

      .connect {
        width: 100%
      }
    }
  }

  .crm {
    div:first-child > {
      .crm_block {
        padding: 23px 18px;
        border-bottom: none;

        h3 {
          font-size: 23px;
          font-weight: 200;
        }
      }
    }

  }

  .integration {
    & > div:last-child {
      padding: 25px 15px;
      border-bottom: none;

      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
      }

      p {
        font-size: 12px;

      }

      .service_block {
        padding: 0;
        border: none;
        margin: 0;
        min-height: 290px;
        border-radius: 0;
      }
    }

    padding: 0
  }

  .carousel_banner_dev {
    h4 {
      text-align: center;
    }
  }

  .services {
    flex-direction: column;
    padding: 0;
    border-radius: 20px 20px 0 0;

    &_install {
      padding: 15px 30px;

      & > div {
        width: calc(100% - 92px);

        h4 {
          font-size: 16px !important;
          text-align: left;

        }
      }

      .content {
        p {
          font-size: 12px !important;
          font-weight: 400;
          text-align: left;
        }
      }

      figure {
        height: 72px;
        width: 72px;
      }
    }

    &_block {
      background: white;
      border-radius: 20px 20px 0 0;
      border-bottom: none;
      padding: 17px 20px;
    }

    .carousel_banner {
      width: 100% !important;
    }

    & > div:first-child {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      figure {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 10px;
        margin: 0;
      }

      h3 {
        font-family: Open Sans, sans-serif;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
      }

      .service_block {
        p {
          font-family: Open Sans, sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;

        }
      }

      .services_install {
        width: 100% !important;
        display: flex;

        & > {

        }

        .content {
          p {
            font-family: Open Sans, sans-serif;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
          }
        }


      }

    }

    .clients {
      padding: 0 20px;
    }

    .services_second_block {
      background: white;

      .client_figure {
        padding: 6px;
        border-radius: 4px;
      }

    }

    .carousel_banner_img_block {
      p {
        text-align: center;
      }

      .carousel_banner_p {
        width: 98%;
        margin-top: 20px
      }
    }

    .carousel_banner {
      background: #00AB64;
    }


    .carousel_banner_2 {
      min-height: 0;

    }

    .carousel_banner_2 .slick-slider.slick-initialized {
      height: 400px;
      min-height: 0;
    }

    .service_host_block {
      margin: 0;
      padding: 20px;
      height: 380px;
    }

    .carousel_banner.resized {
      margin-bottom: 0;
    }
  }


}


.footer {
  .download .inform {
    justify-content: flex-start;
  }
  &_block {
    padding: 20px;
  }

  .small_block {
    width: 100%;
    display: flex;
    flex-direction: row;

    & > div {
      width: 50%;
    }
  }

  &_text {
    margin-top: 20px;
  }

  &_logo_figure {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      object-fit: contain;
    }
  }

  img {
    width: auto !important;
  }

}
ol, ul {
  padding-left: 5px;
}
.footer_text,
.footer_text a,
.footer_nav ul a {
  font-size: 10px;
  color: white;
}
.main .connect{
  border-radius: 0;
}
.main .service_host_block > div:last-child div {
  width: 100%;
  display: flex;
  height: 185px;
}
.footer .copyright {
  display: none;

  img {
    display: none;
  }
}
.main .carousel_banner_2 .carousel_banner_p {
 margin: 0;
}
.aside_menu .aside_nav_child{
  padding-left: 30px;
}


