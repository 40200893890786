.header{
  padding: 0 50px;
  display: flex;
  align-items: center;
  &>div{
    width: 100%;
  }
  figure{
    width: 120px;
    img{
      width: 100%;
    }
  }
  .simple_btn_outline{
    margin: 0 40px;
  }

}
.aside_menu{
  .select_lang{
    left: 25px;
    position: absolute;
    padding: 2px 4px;
    width: 135px;
    top: 25px;
    img{
      margin-right: 5px;
    }
    img.down{
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
}
.main .new-service .service_host_block {
  height: 100%;
  border: none;
  background: white;
  padding: 14px;
  img{
    width: 100%;
  }
}
.main .crm > div:last-child {
  width: 100%;
}
.main{
  .top_banner{
    height: 300px;
  }
  .calculator .price_block td, .calculator .price_block th {
    padding: 3px;
    font-size: 13px;
  }

  .main_container{
    padding: 0 50px;
  }
  .client{
    margin:0 50px;
    padding: 0;
  }
  .carousel_banner {
    margin: 32px 50px 0;
  }
  .carousel_banner_p span{
    text-align: center;
  }
  .service_host_block{
    padding: 0;
  }
  .partnership h3{
    font-size: 24px;
  }
}
.footer{
  &_block{
    padding: 20px 50px;
  }
  .copyright{
    padding: 20px 50px 5px;
  }
}

.aside_menu{
  position: fixed;
  background: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 222;
  transition: all 0.4s;
  right: -100vw;
  .close{
    display: flex;
    justify-content: end;
    margin: 25px;
  }
  .aside_nav{
    padding: 0;
    li{
      padding: 15px 30px;
      border-bottom: 2px solid #cccccc;
      overflow: hidden;
      display: flex;
      align-items: center;
      span,
      a{
        &.active{
          font-weight: bold;
        }
        color:black;
        display: flex;
        align-items: center;
        width:100%;
        img{
          margin-right: 15px;
        }
      }
      &:last-child{
        border-bottom: 2px solid #fff;

      }
    }
    .parent_li{
      border-bottom: 2px solid #cccccc;

    }
    &_child{
      border-bottom: 2px solid #cccccc;

      li{
        border-bottom: none;
        padding: 10px 0;
        a{
          justify-content: space-between;
          width: 100%;
          img{
            width: 62px;
          }
          &.active{
            color:#0973BA
          }
        }
      }
      ._big{
        width: 20px;
      }
      ._small{
        width: 12px;
      }
    }

  }
  .aside_auth{
    padding: 18px;
    h3{
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 400;
      text-align: left;

    }
    .register_btn,
    .simple_btn{
      padding: 10px;
      border-radius: 6px;
      display: inline-block;
      margin: 20px 0;
    }
  }
}
.login {
  background: white;
  padding: 20px;

  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: #3C3C3C;
      margin: 0;
    }

    a {
      font-weight: 700;
    }
  }

  &_block {

    margin-top: 20px;

    & > div {
      width: 100%;

      margin: 12px 0;

      &:first-child {
        align-items: flex-start;
      }

    }

    &_input {
      input {
        outline: none;
        border: none;
        font-family: Roboto Flex, monospace;
        font-size: 14px;
        font-weight: 400;
        width: 90%;
        line-height: 20px;
        text-align: left;
        color: #54565B;

        &::placeholder {
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          color: #54565B
        }
      }

      label {
        height: 36px;
        width: 90%;
        padding: 4px 10px;
        border: 1px solid #D0D0D0;
        border-radius: 6px;
        margin: 3.5px 0;
      }

      p {
        font-family: Open Sans, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        overflow: hidden;
      }
    }

    a {
      margin-left: 10px;
      font-size: 14px;
    }

    &_text {
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 28.8px;
      text-align: left;
      margin-top: 20px
    }
  }

  .simple_btn {
    margin: 0;
    padding: 7px 13px;
    font-family: Open Sans Hebrew, sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    display: inline-block;

  }

  .register_btn {
    margin: 0;
    display: inline-block;
  }
}
.main .connect {
  width: 100%;
}
