.main .top_banner_img {
  display: block;
  object-position: -330px center;
}
.register_btn {
  padding: 10px 15px;
  border-radius: 6px;
  background: #5A99FF;
  color: white;
  font-size: 10px;
}
.main .top_banner{
  min-height: 415px;
}
