.header {
  padding: 0 65px;
  .nav_top{
    li{
      font-size: 14px;
    }
  }
  div {
    margin-top: 60px;
    align-items: flex-end;

    .nav_col {
      height: 60px
    }

    figure {
      width: 235px;

    }

    .selected_lang {
      margin: 0;
    }

    .select_lang {
      top: 58px;
      right: 84px;
    }
  }

}

.header_nav li {
  margin: 0 20px;
  font-size: 15px;
}

.main {
  margin-top: 170px;

  .main_container {
    padding: 0 100px;
  }

  .top_banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 500px;
    background-image: none;
    margin: 0;
    border-radius: 0;
    width: 100%;
    &_content {
      width: 40%;
      padding: 0;

      .h3 {
        font-size: 36px;
        font-weight: 700;
        text-align: left;
      }
    }

    &_p {
      font-size: 24px;
      font-weight: 400;
      text-align: left;
    }

    &_image {
      width: 60%;
      display: block;
    }

    &_img {
      display: block;
      object-fit: cover;
      object-position: right;
      width: 100%;
      border-radius: 23px 5px 5px 23px;
      border: 1px solid #ccc;
      height: 100%;
    }
  }

  .client {
    padding: 0;
    margin: 0 100px;
  }

  .solution {
    div:first-child {
      width: 45%;
      padding: 25px 0;
    }

    div:last-child {
      width: 50%;

      video {
        margin-top: 25px;
        width: 100%

      }
    }

    &_p {
      font-size: 15px;
      line-height: 20px;
    }

    &_title {
      font-size: 30px;
      font-weight: 700;
      text-align: left;
    }

    &_video {
      border-radius: 15px;
      width: 100%;
    }
  }

  .carousel_banner {
    min-height: 350px;
    border-radius: 20px;
    border-top: 1px solid black;
    border-bottom: none;
    margin: 35px 100px 0;
    flex-direction: column;

    &_dot {
      padding: 0;
    }

    &_img_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_1 {
      width: 100%;

      .slick-slider.slick-initialized {
        height: 400px;
        min-height: 400px;
        overflow: hidden;
      }

      & > div {
        padding: 18px;

        h3 {
          font-size: 24px;
          font-weight: 700;
          text-align: left;

        }

        p {
          font-size: 12px;
        }
      }

    }

    &_p {
      width: 80%;
      text-align: left;
      margin: 0;

      span {
        text-align: left;
        display: flex;
      }
    }

    &.resized {
      border: none;
      margin-top: 0;
      margin-bottom: 50px
    }

    &_service {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 5px;

      & > div {
        display: flex;
        align-items: flex-start;

        img {
          width: 30px;
          margin-right: 5px;
          height: 30px;
        }

        .text {
          width: calc(100% - 35px);
        }

        h4 {
          font-size: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 38px;
          line-height: 18px;
          white-space: pre-wrap;
        }

        p {
          font-size: 12px;
          color: #3C3C3C;
        }
      }

      .slick-dots {
        bottom: 0;
        right: 0;
        width: 90%;
      }
    }

    &_2 {
      //height: 100px;
      //min-height: 10/0px;
      min-height: 235px;
      width: 100%;

      &.ishome {
        h4 {
          text-align: left;
          margin-bottom: 20px;
        }

        .carousel_banner_dev {
          color: white; /* Text color */
          font-size: 24px;

        }

        .carousel_banner_p {
          text-align: left;

          span {
            text-align: left;

          }

          .ql-cursor {
            display: none;
          }

          &.text_center {
            p {
              font-size: 13px;
              margin: 0;
              text-align: left;
            }
          }
        }

        .slick-slide.slick-current {
          width: 525px !important;
          margin-top: 0;
          mask-image: linear-gradient(to right, white, white);
          -webkit-mask-image: linear-gradient(to right, white, white); /* For WebKit browsers */
          h4 {
            display: block;
          }
        }

        figure {
          width: 110px;
          height: 110px;

          img {
            width: 110px;
            height: 110px;
          }
        }

        .carousel_banner_img_block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 140px;
          overflow: hidden;
        }

        .slick-slide {
          mask-image: linear-gradient(to right, black, transparent);
          -webkit-mask-image: linear-gradient(to right, black, transparent); /* For WebKit browsers */
        }
        &.ishome{
          h4{
            text-align: left;
            margin-top: 29px;
            display: block;
            margin-left: 16px;
            margin-bottom: 0;
          }
        }
        h4 {
          text-align: left;
          display: none;

        }
      }

      h4 {
        text-align: left;
      }

      .carousel_banner_p {
        width: 80%;
        text-align: center;
        margin: 0 0 0 20px;

        span {
          text-align: center;
        }
      }

      .slick-slider.slick-initialized {
        min-height: 230px;
        overflow: hidden;
        height: 230px;
      }

      .slick-list {
        min-height: 250px;
        height: 250px;
      }

      .carousel_banner_dot {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
      }

      .slick-track {
        min-height: 100px;
      }

      .slick-dots {
        bottom: 75px;
        right: 0;
        width: 100px;

        li.slick-active {
          background: #ffffff;
          width: 27px;
          height: 27px;

          div {
            display: none;
          }
        }
      }

      figure {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        //padding: 20px;
        //width: 20%;
        width: 125px;
        height: 125px;

        img {
          width: 125px;
          height: 125px;
          object-fit: contain;
        }
      }

    }
  }

  .resized {
    flex-direction: column;
    background: #00AB64;
  }

  .service_host_block {
    margin: 20px 100px;
    padding: 0;
    height: 250px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      height: min-content;
    }

    .operator {
      width: 50%;
      min-height: 235px;

      div {
        display: flex;
        flex-flow: nowrap;

        figure {
          height: 40px;

        }
      }

      p {
        font-size: 16px;
        margin: 0;
        line-height: 16px;
        overflow: hidden;
      }

    }

    .install {
      width: 50%;

      h3 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        margin: 0;
        line-height: 16px;
        overflow: hidden;
      }

      display: flex;
      justify-content: space-between;

      div {
        width: 100%;
      }

      figure {
        width: 80px;
        min-width: 80px;
        margin: 0;
        display: flex;
        align-items: center;

        img {
          width: 100%
        }
      }
    }

  }

  .integration,
  .developer {
    & > div:last-child {
      .about_block {
        .control_about {
          h3:has(img) {
            img {
              width: 95px;
            }
          }

          h1 {
            font-size: 24px
          }

          p {
            font-size: 12px;
          }

          h2 {
            font-size: 16px;
          }
        }
      }
    }

    & > div:first-child {
      .crm_item div p, p {
        font-size: 12px;
        line-height: 19px;
      }
    }
  }

  .crm {

  }

  .services.new-service {
    & > div:last-child {
      width: 42%
    }

    & > div:first-child {
      width: 55%;
    }

    .services_install {
      padding: 15px 20px;

      & > div {
        width: calc(100% - 80px);
      }

      figure {
        width: 72px;
        height: 72px;
      }
    }
  }

  .calculator {
    .p-num-prices--panel {
      width: 200px;
      margin-left: 15px;
    }

    .count_block {
      input {
        max-width: 70px;
        font-size: 13px;
      }
    }

    .price_block {
      td, th {
        padding: 5px;
      }
    }
  }

  .services {
    & > div:last-child,
    & > div:first-child {
      width: 48%;

      .service_block {
        margin-bottom: 10px;
        padding: 20px;

        p {
          font-size: 12px;
          font-weight: 400;
          text-align: left;
        }

        h3 {
          font-size: 24px;
          font-weight: 700;
          text-align: left;
          color: #3C3C3C;
        }
      }

      .services_install {
        display: flex;

        h3 {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
        }

        .content {
          p {
            font-size: 12px;
            font-weight: 400;
            text-align: left;
          }
        }

        figure {
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 10px;
          margin: 0;
        }
      }
    }

    .carousel_banner_dev {
      padding: 20px 5px;
    }

    .carousel_banner {
      &_dot {
        padding: 0;
      }

      &_img_block {
        flex-direction: column;
      }

      &_p {
        width: 80%;
      }


      &.resized {
        border: none;
        margin-top: 0;
        margin-bottom: 50px
      }

      &_service {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;

        .slick-dots {
          bottom: 0;
          right: 0;
          width: 90%;
        }
      }

      &_2 {
        min-height: 575px;
        .slick-slider.slick-initialized {
          min-height: 575px;
          overflow: hidden;
          height: 575px;
        }

        h4 {
          text-align: center;
        }

        .slick-list {
          min-height: 575px;
          height: 575px;
        }

        .carousel_banner_dot {
          flex-direction: row;
        }

        .slick-track {
          min-height: 100px;
        }

        .slick-dots {
          bottom: 0;
          right: 0;
          width: 155px;
          left: 0;
          margin: auto;

          li.slick-active {
            background: #ffffff;
            width: 27px;
            height: 27px;

            div {
              display: none;
            }
          }
        }

        figure {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 20px;
          width: 140px;
          height: 140px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

      }
    }

    .solution {
      div:first-child {
        width: 100%;

      }

      width: 100%;

    }


  }

  .images {
    figure {
      width: 75px;

      img {
        height: 75px;
      }
    }
  }
}

.footer {
  .copyright {
    padding: 20px 115px 20px;
  }
}

.footer_block {
  padding: 20px 120px;

  .download {
    a {
      margin: 0

    }
  }
}
.main .carousel_banner_1 .slick-list {
  height: 350px;
  padding: 1px;
}
@media only screen and (max-width: 1024px){
  .main .service_host_block {
    margin: 0;
    padding: 0;
    height: 250px;
  }
}

